<template>
    <nav class="bg-custom-darkblue p-4 flex justify-end items-center">
        <router-link class="text-white mr-4 hover:text-blue-300" to="/"
            >Home</router-link
        >
        <router-link class="text-white mr-4 hover:text-blue-300" to="/trainers"
            >Trainers</router-link
        >
        <div
            v-if="$store.state.user"
            class="relative"
            v-click-outside="closeDropdown"
        >
            <button
                @click="toggleDropdown"
                class="flex items-center text-white"
            >
                <img
                    :src="$store.state.user.profile.profile_pic ?? maleAvatar"
                    alt="Profile Pic"
                    class="w-8 h-8 rounded-full mr-2"
                />
                <span>{{ $store.state.user.username }}</span>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 ml-1"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                >
                    <path
                        fill-rule="evenodd"
                        d="M10 12a1 1 0 0 1-.707-.293l-4-4a1 1 0 0 1 1.414-1.414L10 10.586l3.293-3.293a1 1 0 1 1 1.414 1.414l-4 4A1 1 0 0 1 10 12z"
                        clip-rule="evenodd"
                    />
                </svg>
            </button>
            <div
                v-if="isDropdownOpen"
                class="absolute right-0 mt-5 w-48 bg-white rounded-md overflow-hidden shadow-xl z-10"
            >
                <div class="px-4 py-3 bg-custom-grey text-white">
                    <p class="font-bold">
                        {{ $store.state.user.username }}
                    </p>
                    <p class="text-sm">{{ $store.state.user.email }}</p>
                </div>
                <router-link
                    class="block px-4 py-2 text-sm text-gray-700 hover:bg-custom-darkblue-2 text-left hover:text-white"
                    to="/profile"
                >
                    Profile
                </router-link>
                <button
                    class="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-custom-darkblue-2 hover:text-white"
                    @click="logout"
                >
                    Logout
                </button>
            </div>
        </div>
    </nav>
</template>

<script>
import maleAvatar from "@/assets/svg/male_avatar.svg";
import { useToast } from "vue-toast-notification";
import "vue-toast-notification/dist/theme-default.css";
export default {
    name: "App",

    data() {
        return {
            isDropdownOpen: false,
            maleAvatar,
        };
    },
    directives: {
        clickOutside: {
            beforeMount: (el, binding) => {
                el.clickOutsideEvent = (event) => {
                    // here I check that click was outside the el and his children
                    if (!(el == event.target || el.contains(event.target))) {
                        // and if it did, call method provided in attribute value
                        binding.value();
                    }
                };
                document.body.addEventListener("click", el.clickOutsideEvent);
            },
            beforeUnmount: (el) => {
                document.body.removeEventListener(
                    "click",
                    el.clickOutsideEvent
                );
            },
        },
    },
    methods: {
        toggleDropdown() {
            this.isDropdownOpen = !this.isDropdownOpen;
        },
        closeDropdown() {
            this.isDropdownOpen = false;
        },
        logout() {
            const toast = useToast();
            this.$store
                .dispatch("logout")
                .then(() => {
                    this.isDropdownOpen = false;
                    localStorage.removeItem("access_token");
                    this.$router.push("/");
                    toast.success("Logged out successfully");
                })
                .catch((error) => console.error("Error logging out:", error));
        },
    },
};
</script>
