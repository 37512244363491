<template>
    <section
        class="bg-gradient-to-tl from-custom-brown to-custom-litebrown py-10 lg:py-[60px] dark:bg-dark"
    >
        <h1
            class="text-6xl text-center text-custom-darkblue font-bold py-10 m-10"
        >
            Welcome to OneChess
        </h1>
        <router-link to="/login">
            <button
                class="bg-custom-darkblue hover:bg-custom-darkblue-2 text-custom-litebrown font-bold m-4 py-4 px-8 rounded"
            >
                Login
            </button>
        </router-link>
        <router-link to="/signup">
            <button
                class="bg-custom-darkblue hover:bg-custom-darkblue-2 text-custom-litebrown font-bold m-4 py-4 px-8 rounded ml-4"
            >
                Sign Up
            </button>
        </router-link>
    </section>
</template>

<script>
import { useHead } from "@vueuse/head";
export default {
    setup() {
        useHead({
            title: "OneChess | The Ultimate Online Chess Experience",
            meta: [
                {
                    name: "description",
                    content:
                        "OneChess offers the ultimate online chess experience. Play, learn, and compete with players from around the world. Join us and make your move!",
                },
            ],
            link: [
                {
                    rel: "canonical",
                    href: process.env.VUE_APP_DOMAIN,
                },
            ],
        });
    },
    name: "HomePage",
    props: {
        msg: String,
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
