<template>
    <div
        id="app"
        class="font-sans text-center text-blue-900 flex flex-col min-h-screen"
    >
        <!-- <nav v-if="$route.name !== 'Signup'" class="bg-custom-darkblue p-4 flex justify-end"> -->
        <NavBar />
        <router-view class="flex-grow" />
    </div>
</template>

<script>
import NavBar from "./components/Layout/NavBar.vue";
import { computed, reactive } from "vue";
import { useHead } from "@vueuse/head";
export default {
    name: "App",
    components: {
        NavBar,
    },
    setup() {
        const siteData = reactive({
            title: "OneChess | The Ultimate Online Chess Experience",
            description:
                "OneChess offers the ultimate online chess experience. Play, learn, and compete with players from around the world. Join us and make your move!",
            url: process.env.VUE_APP_DOMAIN,
        });
        useHead({
            title: computed(() => siteData.title),
            meta: [
                {
                    name: "description",
                    content: computed(() => siteData.description),
                },
                {
                    name: "keywords",
                    content: "chess, online chess, chess game, chess online",
                },
                {
                    name: "robots",
                    content: "index, follow",
                },
            ],
            link: [
                {
                    rel: "canonical",
                    href: computed(() => siteData.url),
                },
            ],
        });
    },
};
</script>
