import { createRouter, createWebHistory } from 'vue-router'
import HomePage from './components/HomePage.vue'
import AuthLogin from './components/Auth/AuthLogin.vue'
import AuthRegister from './components/Auth/AuthRegister.vue'
import UserProfile from './components/UserProfile.vue'
import TrainerList from './components/TrainerList.vue'
import store from './store'; 

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/', component: HomePage ,name: 'Home' },
    { path: '/login', component: AuthLogin ,name: 'Login' },
    { path: '/signup', component: AuthRegister, name: 'Signup' },
    { path: '/profile', component: UserProfile, name: 'Profile' , meta: { requiresAuth: true } },
    { path: '/trainers', component: TrainerList, name: 'Trainers' },
    // other routes...
    { path: '/:pathMatch(.*)*', redirect: '/' } // catch-all non exisitng routes
  ]
})

router.beforeEach((to, from, next) => {
  // check if the route requires authentication
  if (to.meta.requiresAuth) {
    // check if the user is authenticated
    if (!store.state.user) {
      // if not, redirect to the login page
      next({ name: 'Login' });
    } else {
      // if yes, proceed to the route
      next();
    }
  } else {
    // if the route doesn't require authentication, proceed to the route
    next();
  }
});

export default router