<template>
    <section
        class="flex-grow bg-gradient-to-tl from-custom-darkblue-2 to-custom-darkblue py-10 lg:py-[60px] dark:bg-dark"
    >
        <div class="container mx-auto px-4">
            <h1 class="text-4xl font-bold mb-4 py-4 text-custom-litebrown">
                Trainers
            </h1>
            <div class="grid grid-cols-1 sm:grid-cols-5 gap-4 mx-auto">
                <div
                    class="col-start-1 sm:col-start-2 col-span-full sm:col-span-3 grid grid-cols-1 sm:grid-cols-3 gap-4"
                >
                    <div
                        v-for="trainer in trainers"
                        :key="trainer.id"
                        class="card bg-gradient-to-tl from-custom-brown to-custom-litebrown rounded-lg"
                    >
                        <img
                            :src="trainer.profile_pic ?? maleAvatar"
                            alt="Profile Pic"
                            class="w-20 h-20 rounded-full mx-auto mt-4 bg-custom-white"
                        />
                        <h2
                            class="text-center font-bold text-xl mt-2 text-custom-darkblue"
                        >
                            {{ trainer.full_name }}
                        </h2>
                        <p class="text-center text-custom-darkblue">
                            {{ trainer.username }}
                        </p>
                        <p class="text-center text-custom-darkblue">
                            {{ trainer.country ?? "India" }}
                        </p>
                    </div>
                </div>
            </div>
            <button
                v-if="hasNextPage"
                @click="loadMore"
                class="bg-custom-darkblue hover:bg-custom-darkblue-2 text-custom-litebrown font-bold py-2 px-4 rounded mt-4"
            >
                Load More
            </button>
        </div>
    </section>
</template>

<script>
import axios from "axios";
import maleAvatar from "@/assets/svg/male_avatar.svg";
import { useHead } from "@vueuse/head";
export default {
    setup() {
        useHead({
            title: "Trainers | OneChess",
            meta: [
                {
                    name: "description",
                    content: "OneChess Trainers Listing Page",
                },
            ],
            link: [
                {
                    rel: "canonical",
                    href: process.env.VUE_APP_DOMAIN + "/trainers",
                },
            ],
        });
    },
    data() {
        return {
            trainers: [],
            page: 1,
            perPage: 3,
            hasNextPage: false,
            maleAvatar,
        };
    },
    methods: {
        loadMore() {
            axios
                .get(
                    `https://api.theonechess.com/api/trainer/list?page=${this.page}&per_page=${this.perPage}`
                )
                .then((response) => {
                    this.trainers = [...this.trainers, ...response.data.data];
                    this.hasNextPage = response.data.links.next ? true : false;
                    this.page++;
                })
                .catch((error) => {
                    console.error(error);
                });
        },
    },
    mounted() {
        this.loadMore();
    },
};
</script>

<style scoped>
.card {
    border: 1px solid #e5e7eb;
    border-radius: 0.375rem;
    padding: 1rem;
}
</style>
