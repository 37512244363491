import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import store from './store'; 
import { createHead } from '@vueuse/head';
import './assets/css/tailwind.css';

axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL

axios.interceptors.request.use(function (config) {
    const token = localStorage.getItem('access_token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  }, function (error) {
    return Promise.reject(error);
  });

const app = createApp(App);
app.use(createHead());
app.use(router);
app.use(store);
app.mount('#app');