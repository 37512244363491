<!-- Login.vue -->
<template>
    <section
        class="flex-grow bg-gradient-to-tl from-custom-darkblue-2 to-custom-darkblue py-10 lg:py-[60px] dark:bg-dark"
    >
        <div class="container mx-auto max-w-screen-md">
            <div class="-mx-4 flex flex-wrap">
                <div class="w-full px-4">
                    <div
                        class="relative mx-auto max-w-[400px] overflow-hidden rounded-lg bg-gradient-to-tl from-custom-brown to-custom-litebrown px-10 py-8 text-center sm:px-12 md:px-[30px] dark:bg-dark-2"
                    >
                        <div class="mb-5 text-center md:mb-8">
                            <router-link
                                to="/"
                                class="mx-auto inline-block max-w-[80px]"
                            >
                                <img
                                    src="../../assets/images/logo/logo.png"
                                    alt="logo"
                                />
                            </router-link>
                        </div>
                        <form @submit.prevent="login">
                            <div class="mb-6">
                                <input
                                    id="email-address"
                                    name="email"
                                    type="email"
                                    v-model="email"
                                    required
                                    placeholder="Email"
                                    class="w-full rounded-md border-2 border-custom-brown bg-transparent px-5 py-3 text-base text-custom-darkblue outline-none focus:border-2 focus:border-custom-darkblue-2 focus-visible:shadow-none dark:border-dark-3 dark:text-white placeholder-custom-grey"
                                />
                            </div>
                            <div class="mb-6 relative">
                                <input
                                    id="password"
                                    name="password"
                                    type="password"
                                    v-model="password"
                                    required
                                    placeholder="Password"
                                    class="w-full rounded-md border-2 border-custom-brown bg-transparent px-5 py-3 text-base text-custom-darkblue outline-none focus:border-2 focus:border-custom-darkblue-2 focus-visible:shadow-none dark:border-dark-3 dark:text-white placeholder-custom-grey"
                                />
                                <button
                                    type="button"
                                    @click="showPassword = !showPassword"
                                    class="absolute right-3 top-3"
                                >
                                    {{ showPassword ? "Hide" : "Show" }}
                                </button>
                            </div>
                            <div class="mb-10">
                                <input
                                    type="submit"
                                    value="Sign In"
                                    class="w-full cursor-pointer rounded-md border border-custom-darkblue-2 bg-custom-darkblue px-5 py-3 text-base font-medium text-custom-litebrown transition hover:bg-opacity-90"
                                />
                            </div>
                        </form>

                        <a
                            href="javascript:void(0)"
                            class="mb-2 inline-block text-base text-dark hover:text-primary hover:underline dark:text-white"
                        >
                            Forget Password?
                        </a>
                        <p class="text-base text-dark dark:text-dark-6">
                            <span class="pr-0.5">Not a member yet?</span>
                            <router-link
                                to="/signup"
                                class="text-custom-darkblue hover:underline"
                            >
                                Sign Up
                            </router-link>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from "axios";
import { useHead } from "@vueuse/head";
export default {
    setup() {
        useHead({
            title: "Login | OneChess",
            meta: [
                {
                    name: "description",
                    content: "OneChess Login Page",
                },
            ],
            link: [
                {
                    rel: "canonical",
                    href: process.env.VUE_APP_DOMAIN + "/login",
                },
            ],
        });
    },
    data() {
        return {
            email: "",
            password: "",
            showPassword: false,
        };
    },
    methods: {
        async login() {
            try {
                const response = await axios.post("/auth/login", {
                    email: this.email,
                    password: this.password,
                });

                console.log(response.data);
                console.log(response.data); // Save the user data
                this.$store.commit("setUser", response.data.user);

                localStorage.setItem(
                    "access_token",
                    response.data.authorization.token
                );

                // Set up Axios to include the token in the Authorization header
                axios.defaults.headers.common[
                    "Authorization"
                ] = `Bearer ${response.data.authorization.token}`;

                // Redirect to the UserProfile.vue page
                this.$router.push({ name: "Profile" });
            } catch (error) {
                console.error(error);
            }
        },
    },
};
</script>
