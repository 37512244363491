<template>
    <section
        class="flex-grow flex flex-col items-center justify-center bg-gradient-to-tl from-custom-darkblue-2 to-custom-darkblue py-10 lg:py-[60px] dark:bg-dark"
    >
        <!-- <div
            class="flex flex-col items-center justify-center min-h-screen bg-gray-100 py-2"
        > -->
        <div
            class="max-w-xl w-full space-y-8 bg-gradient-to-tl from-custom-brown to-custom-litebrown p-10 rounded-lg shadow-md"
        >
            <div class="flex justify-between items-center mb-6">
                <h2 class="text-2xl font-bold text-gray-700">User Profile</h2>
            </div>
            <div class="grid grid-cols-3 gap-4 items-start">
                <label
                    class="text-custom-darkblue dark:text-white text-sm font-medium"
                    >Username</label
                >
                <span
                    class="col-span-2 text-custom-darkblue dark:white text-left"
                    >{{ user?.username }}</span
                >

                <label
                    class="text-custom-darkblue dark:text-white text-sm font-medium"
                    >Email</label
                >
                <span
                    class="col-span-2 text-custom-darkblue dark:text-white text-left"
                    >{{ user?.email }}</span
                >

                <label
                    class="text-custom-darkblue dark:text-white text-sm font-medium"
                    >First Name</label
                >
                <input
                    v-model="first_name"
                    class="border rounded-md p-2 w-full text-left col-span-2"
                />

                <label
                    class="text-custom-darkblue dark:text-white text-sm font-medium"
                    >Last Name</label
                >
                <input
                    v-model="last_name"
                    class="border rounded-md p-2 w-full text-left col-span-2"
                />
                <label
                    class="text-custom-darkblue dark:text-white text-sm font-medium"
                    >Mobile</label
                >
                <input
                    v-model="mobile"
                    class="border rounded-md p-2 w-full text-left col-span-2"
                />
                <label
                    class="text-custom-darkblue dark:text-white text-sm font-medium"
                    >Gender</label
                >
                <select
                    v-model="gender"
                    class="border rounded-md p-2 w-full text-left col-span-2"
                >
                    <option value="0" disabled>Not selected</option>
                    <option value="1">Male</option>
                    <option value="2">Female</option>
                    <option value="3">Other</option>
                </select>
                <label
                    class="text-custom-darkblue dark:text-white text-sm font-medium"
                    >Date of Birth</label
                >
                <input
                    v-model="dob"
                    type="date"
                    min="1990-01-01"
                    class="border rounded-md p-2 w-full text-left col-span-2"
                />
                <label
                    class="text-custom-darkblue dark:text-white text-sm font-medium"
                    >Address</label
                >
                <textarea
                    v-model="address"
                    class="border rounded-md p-2 w-full text-left col-span-2"
                ></textarea>
                <label
                    class="text-custom-darkblue dark:text-white text-sm font-medium"
                    >Country</label
                >
                <select
                    v-model="country"
                    class="border rounded-md p-2 w-full text-left col-span-2"
                >
                    <option
                        v-for="country in countries"
                        :key="country.code"
                        :value="country.name"
                    >
                        {{ country.name }}
                    </option>
                </select>
            </div>
            <div class="mt-6">
                <button
                    @click="update"
                    class="px-4 py-2 bg-custom-darkblue text-custom-litebrown rounded-md hover:bg-custom-darkblue-2"
                >
                    Save
                </button>
            </div>
        </div>
        <!-- </div> -->
    </section>
</template>
<script>
import { getNames } from "country-list";
import axios from "axios";
import { useHead } from "@vueuse/head";
import { useToast } from "vue-toast-notification";
import "vue-toast-notification/dist/theme-default.css";

function generateComputedProperties(properties) {
    return properties.reduce((acc, property) => {
        acc[property] = {
            get() {
                return (
                    this.user &&
                    this.user.profile &&
                    this.user.profile[property]
                );
            },
            set(newValue) {
                if (this.user && this.user.profile) {
                    this.user.profile[property] = newValue;
                }
            },
        };
        return acc;
    }, {});
}

export default {
    setup() {
        useHead({
            title: "Profile | OneChess",
            meta: [
                {
                    name: "description",
                    content: "OneChess Profile Page",
                },
            ],
            link: [
                {
                    rel: "canonical",
                    href: process.env.VUE_APP_DOMAIN + "/profile",
                },
            ],
        });
    },
    computed: {
        user() {
            return this.$store.state.user;
        },
        countries() {
            return getNames().map((name, i) => ({ name, code: i }));
        },
        ...generateComputedProperties([
            "first_name",
            "last_name",
            "mobile",
            "gender",
            "dob",
            "address",
            "country",
        ]),
    },
    methods: {
        async update() {
            const toast = useToast();
            try {
                const response = await axios.post("/user/update-profile", {
                    first_name: this.first_name,
                    last_name: this.last_name,
                    mobile: this.mobile,
                    gender: this.gender,
                    dob: this.dob,
                    address: this.address,
                    country: this.country,
                });

                console.log(response.data); // Save the user data
                this.$store.commit("setUser", response.data.user);

                if (response.status === 200) {
                    toast.success("Profile updated successfully");
                } else {
                    toast.error("Failed to update profile");
                }
            } catch (error) {
                toast.error("An error occurred while updating profile");
            }
        },
    },
};
</script>
