import axios from 'axios';
import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';

export default createStore({
  state: {
    user: null,
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    clearUser(state) {
      state.user = null;
    },
  },
  actions: {
    async logout({ commit }) {
      try {
        await axios.post('/auth/logout'); // Replace '/logout' with your logout URL
        commit('clearUser');
      } catch (error) {
        console.error('Error logging out:', error);
      }
    },
  },
  plugins: [createPersistedState()],
});